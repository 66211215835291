.social-sharing {
    display: flex;
    flex-direction: column;
    row-gap: 28px;
    padding-bottom: 100px;
}

.post-bottom-bar {
    display: flex;
    align-items: center;
    background: #1c2835;
    padding: 0px 18px;
    width: 100%;
    border-top: 1px solid #ddd;
    z-index: 1;
}

/* .post-bottom-bar div:first-child {
    width: 100%;
} */

.post-bottom-bar .subcrible {
    margin-bottom: 0;
    margin-right: 30px;
}

.post-bottom-bar .social-sharing {
    flex-direction: row-reverse;
    margin: 0;
    padding: 0;
    column-gap: 29px;
    align-items: center;
    justify-content: center;
}
