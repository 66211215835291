.markdown-body h1,
.markdown-body h2,
.markdown-body h3,
.markdown-body h4,
.markdown-body h5,
.markdown-body h6 {
    position: relative;
    margin-top: 1.5em;
    margin-bottom: 16px;
    font-weight: 700;
    line-height: 1.4;
}

.markdown-body h1 {
    margin-top: 0.5em;
}

.markdown-body ol,
.markdown-body ul {
    padding-left: 2em;
}

.markdown-body li:not(:last-child) {
    margin-bottom: 8px;
}

.markdown-body blockquote,
.markdown-body dl,
.markdown-body ol,
.markdown-body p,
.markdown-body pre,
.markdown-body table,
.markdown-body ul {
    margin-top: 0;
    margin-bottom: 16px;
}

.markdown-body blockquote {
    margin: 15px 0;
    padding: 10px 20px;
    background: #121212;
    border-radius: 5px;
    font-style: italic;
}

.markdown-body blockquote::before {
    content: '\201C';
}

.markdown-body blockquote::after {
    content: '\201D';
}
.markdown-body table {
    border-collapse: collapse;
    border-spacing: 0;
    width: auto;
    overflow: auto;
    word-break: normal;
    word-break: keep-all;
    margin-bottom: 16px;
    margin: 1.6em auto;
}

.markdown-body table tr {
    background-color: #121212;
    border-top: 1px solid #ccc;
}

.markdown-body table td,
.markdown-body table th {
    padding: 8px 15px;
    border: 1px solid #ddd;
}

.markdown-body table th {
    background-color: #3d3d3d;
    font-size: 19px;
}
.markdown-body table tr:nth-child(2n) {
    background-color: #3d3d3d;
}
