@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700|Poppins:300,400,500,600,700&display=swap');

html {
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    font-style: normal;
    color: #747e88;
    overflow-x: hidden;
}

*,
*:before,
*:after {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

img {
    max-width: 100%;
}

:root {
    --main-color: #b4975d;
    --blue-700: #e5efff;
    --blue-650: #c8deff;
    --blue-600: #abcdff;
    --blue-500: #72abff;
    --blue-550: #2196f3;
    --blue-400: #3989ff;
    --blue-300: #0068ff;
    --blue-base: #1976d2;
    --dark-blue-200: #004bb9;
    --dark-blue-base: #004bb9;
    --grey-700: #fbfbfd;
    --grey-600: #f4f5f7;
    --grey-500: #eeeff2;
    --grey-400: #e8eaef;
    --grey-300: #e1e4ea;
    --grey-base: #e1e4ea;
    --dark-grey-700: #060707;
    --dark-grey-600: #121415;
    --dark-grey-500: #363b3e;
    --dark-grey-400: #666f76;
    --dark-grey-300: #96a3ad;
    --dark-grey-base: #96a3ad;
    --red-700: #fbebe9;
    --red-600: #f3bcbb;
    --red-500: #eb8e8b;
    --red-400: #e2615d;
    --red-300: #db342e;
    --red-base: #db342e;
    --orange-700: #fdf3e9;
    --orange-600: #fbd6bb;
    --orange-500: #f9ba8c;
    --orange-400: #f79e5d;
    --orange-300: #f5832f;
    --orange-base: #f5832f;
    --neutral-700: #e5e7eb;
    --neutral-600: #abb4bc;
    --neutral-500: #72808e;
    --neutral-400: #394e60;
    --neutral-300: #001a33;
    --neutral-200: #081020;
    --neutral-100: #050a19;
    --neutral-base: #001a33;
    --green-700: #e7f5ef;
    --green-600: #b2e2cb;
    --green-500: #7ecea7;
    --green-400: #49bb82;
    --green-300: #15a85f;
    --green-base: #15a85f;
    --yellow-700: #fff9e5;
    --yellow-600: #ffefab;
    --yellow-500: #ffe472;
    --yellow-400: #ffda39;
    --yellow-300: #ffd000;
    --yellow-200: #ffd20a;
    --yellow-100: #f5c801;
    --yellow-base: #ffd000;
    --purple-700: #f1effb;
    --purple-600: #d1ccf2;
    --purple-500: #b3a8e9;
    --purple-400: #9485e0;
    --purple-300: #7562d8;
    --purple-base: #7562d8;
    --pink-700: #fdf3ff;
    --pink-600: #f7d8ff;
    --pink-500: #f2bdff;
    --pink-400: #efa1ff;
    --pink-300: #ea87ff;
    --pink-base: #ea87ff;
    --teal-700: #e5f7f9;
    --teal-600: #abe8e9;
    --teal-500: #72d8db;
    --teal-400: #39cace;
    --teal-300: #00bac0;
    --teal-base: #00bac0;
    --gradient-blue: linear-gradient(45deg, #408dfd, #0068ff);
    --gradient-sky-blue: linear-gradient(225deg, #00bac0, #72abff);
    --gradient-pink: linear-gradient(225deg, #ea87ff, #f2bdff);
    --gradient-orange: linear-gradient(225deg, #db342e 100%, #f5832f 0);
    --gradient-green: linear-gradient(225deg, #ffda39, #49bb82);
    --gradient-red-pink: linear-gradient(199deg, #eb8e8b, #f2bdff);
    --gradient-light-pink: linear-gradient(199deg, #ea87ff, #f2bdff);
    --gradient-purple-pink: linear-gradient(199deg, #b3a8e9, #f2bdff);
    --gradient-light-purple: linear-gradient(199deg, #7562d8, #b3a8e9);
    --gradient-purple-blue: linear-gradient(199deg, #7562d8, #0068ff);
    --gradient-light-blue: linear-gradient(199deg, #72abff, #0068ff);
    --gradient-teal-blue: linear-gradient(199deg, #00bac0, #0068ff);
    --gradient-teal-green: linear-gradient(199deg, #00bac0, #15a85f);
    --gradient-light-green: linear-gradient(199deg, #15a85f, #49bb82);
    --gradient-yellow-green: linear-gradient(199deg, #ffda39, #49bb82);
    --gradient-orange-yellow: linear-gradient(199deg, #f5832f, #ffd000);
    --gradient-red-orange: linear-gradient(199deg, #db342e, #f5832f);
    --shades-left-bar: #0091ff;
    --shades-left-bar-active: #006edc;
    --shades-photo-bg: #545454;
    --box-input: rgba(0, 133, 255, 0.25);
    --box-input2: rgba(24, 144, 255, 0.2);
    --box-grey: rgba(0, 0, 0, 0.02);
    --box-notif: rgba(0, 26, 51, 0.1);
    --box-popover: rgba(0, 0, 0, 0.18);
    --transparent-blue08: rgba(0, 104, 255, 0.8);
    --white-700: hsla(0, 0%, 100%, 0.1);
    --white-600: hsla(0, 0%, 100%, 0.25);
    --white-500: hsla(0, 0%, 100%, 0.5);
    --white-400: hsla(0, 0%, 100%, 0.75);
    --white-300: #fff;
    --white-base: #fff;
    --black-700: rgba(0, 0, 0, 0.1);
    --black-600: rgba(0, 0, 0, 0.25);
    --black-500: rgba(0, 0, 0, 0.5);
    --black-400: #101010;
    --black-300: #000;
    --black-base: #000;
    --facebook-bg: #4267b2;
    --color-fg-default: #c9d1d9;
    --color-fg-muted: #8b949e;
}

html,
body {
    min-height: 100%;
}
body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding: 0 !important;
    margin: 0 !important;
}

.background-overlay {
    z-index: -1;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    opacity: 0.15;
    background-repeat: repeat-y;
    background-image: url(./assets/images/astrology-bg.png);
}

#section {
    background-color: var(--black-400);
    padding: 0;
    margin: 0 auto;
    /* max-width: 1920px; */
    /* padding-top: 2rem; */
    /* min-height: calc(100vh - 552px); */
    /* min-height: 100vh; */
    /* background-image: url(./assets/images/astrology-bg.png); */
    /* background-image: linear-gradient(
            147deg,
            rgba(18, 18, 18, 0.7) 0%,
            rgba(33, 33, 44, 0.7) 74%
        ),
        url(./assets/images/astrology-bg.png); */
}

.home-wrapper {
    /* margin-top: 3rem; */
    padding: 3rem 44px 60px;
}
@media (min-width: 740px) and (max-width: 1023px) {
    .home-wrapper {
        padding: 2rem 32px 60px;
    }
}

@media (min-width: 0px) and (max-width: 739px) {
    .home-wrapper {
        padding: 1.6rem 16px 60px;
    }
}

a:focus,
input:focus,
textarea:focus,
button:focus {
    text-decoration: none;
    outline: none;
}

a:focus,
a:hover {
    text-decoration: none;
}

i,
span,
a {
    display: inline-block;
}

audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    color: var(--color-fg-default);
    margin: 0px;
}

h1 {
    font-size: 48px;
}

h2 {
    font-size: 36px;
}

h3 {
    font-size: 28px;
}

h4 {
    font-size: 22px;
}

h5 {
    font-size: 18px;
}

h6 {
    font-size: 16px;
}

ul,
ol {
    margin: 0px;
    padding: 0px;
    list-style-type: none;
}

p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: var(--color-fg-default);
    margin: 0px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.primary-paper {
    z-index: 99;
    background-color: #1f2c3a !important;
    background-image: none !important;
}

.primary-card {
    /* background-color: transparent !important; */
    /* background-color: rgba(95, 94, 94, 0.6) !important; */
    background-image: none !important;
}

.primary-card:hover {
    box-shadow: -1px 10px 29px 0px rgba(154, 154, 154, 0.16);
}

h3.title {
    font-size: 1.17rem;
}

.bg_cover {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
}

.main-btn {
    display: inline-block;
    font-weight: 500;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 2px solid #0898e7;
    padding: 0 25px;
    font-size: 16px;
    height: 55px;
    line-height: 51px;
    border-radius: 5px;
    color: var(--color-fg-default);
    cursor: pointer;
    z-index: 5;
    -webkit-transition: all 0.4s ease-out 0s;
    -moz-transition: all 0.4s ease-out 0s;
    -ms-transition: all 0.4s ease-out 0s;
    -o-transition: all 0.4s ease-out 0s;
    transition: all 0.4s ease-out 0s;
    background-color: #0898e7;
}
.main-btn:hover {
    background-color: rgba(8, 152, 231, 0.7);
    color: var(--color-fg-default);
    border-color: transparent;
}
.main-btn.main-btn-2 {
    background-color: transparent;
    color: #525a63;
    border-color: #525a63;
}
.main-btn.main-btn-2:hover {
    background-color: rgba(82, 90, 99, 0.5);
    color: #525a63;
}

@keyframes animation1 {
    0% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }
    50% {
        -webkit-transform: scale(1.2);
        -moz-transform: scale(1.2);
        -ms-transform: scale(1.2);
        -o-transform: scale(1.2);
        transform: scale(1.2);
    }
    100% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }
}

@-webkit-keyframes animation1 {
    0% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }
    50% {
        -webkit-transform: scale(1.2);
        -moz-transform: scale(1.2);
        -ms-transform: scale(1.2);
        -o-transform: scale(1.2);
        transform: scale(1.2);
    }
    100% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }
}

/*===== All Section Title Style =====*/
.section-title .title {
    font-size: 35px;
    font-weight: 600;
}
@media (max-width: 767px) {
    .section-title .title {
        font-size: 22px;
    }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
    .section-title .title {
        font-size: 26px;
    }
}

.section-title .text {
    margin-top: 20px;
}

/*===== All Slick Slide Outline Style =====*/
.slick-slide {
    outline: 0;
}

/*===========================
      02.HEADER css 
===========================*/
/*===== NAVBAR =====*/
.navbar-area {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

.sticky {
    position: fixed;
    z-index: 99;
    background-color: #fff;
    -webkit-box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.05);
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}
.sticky .navbar {
    padding: 10px 0;
}

.navbar {
    padding: 0;
    border-radius: 5px;
    position: relative;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    padding: 35px 0;
}

.navbar-brand {
    padding: 0;
}
.navbar-brand img {
    max-width: 150px;
}

.navbar-toggler {
    padding: 0;
}
.navbar-toggler .toggler-icon {
    width: 30px;
    height: 2px;
    background-color: #222;
    display: block;
    margin: 5px 0;
    position: relative;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}
.navbar-toggler.active .toggler-icon:nth-of-type(1) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 7px;
}
.navbar-toggler.active .toggler-icon:nth-of-type(2) {
    opacity: 0;
}
.navbar-toggler.active .toggler-icon:nth-of-type(3) {
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
    top: -7px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .navbar-collapse {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: #fff;
        z-index: 9;
        -webkit-box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
        padding: 5px 12px;
    }
}

@media (max-width: 767px) {
    .navbar-collapse {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: #fff;
        z-index: 9;
        -webkit-box-shadow: 0px 15px 20px 0px rgba(56, 66, 77, 0.1);
        -moz-box-shadow: 0px 15px 20px 0px rgba(56, 66, 77, 0.1);
        box-shadow: 0px 15px 20px 0px rgba(56, 66, 77, 0.1);
        padding: 5px 12px;
    }
}

.navbar-nav .nav-item {
    margin-left: 45px;
    position: relative;
}
.navbar-nav .nav-item:first-child {
    margin-left: 0;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .navbar-nav .nav-item {
        margin-left: 30px;
    }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .navbar-nav .nav-item {
        margin: 0;
    }
}
@media (max-width: 767px) {
    .navbar-nav .nav-item {
        margin: 0;
    }
}
.navbar-nav .nav-item a {
    font-size: 16px;
    font-weight: 600;
    color: var(--color-fg-default);
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    position: relative;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .navbar-nav .nav-item a {
        display: block;
        padding: 4px 0;
    }
}
@media (max-width: 767px) {
    .navbar-nav .nav-item a {
        display: block;
        padding: 4px 0;
    }
}
.navbar-nav .nav-item.active > a,
.navbar-nav .nav-item:hover > a {
    color: #0898e7;
}
.navbar-nav .nav-item:hover .sub-menu {
    top: 100%;
    opacity: 1;
    visibility: visible;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .navbar-nav .nav-item:hover .sub-menu {
        top: 0;
    }
}
@media (max-width: 767px) {
    .navbar-nav .nav-item:hover .sub-menu {
        top: 0;
    }
}
.navbar-nav .nav-item .sub-menu {
    width: 200px;
    background-color: #fff;
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 110%;
    left: 0;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .navbar-nav .nav-item .sub-menu {
        position: relative;
        width: 100%;
        top: 0;
        display: none;
        opacity: 1;
        visibility: visible;
    }
}
@media (max-width: 767px) {
    .navbar-nav .nav-item .sub-menu {
        position: relative;
        width: 100%;
        top: 0;
        display: none;
        opacity: 1;
        visibility: visible;
    }
}
.navbar-nav .nav-item .sub-menu li {
    display: block;
}
.navbar-nav .nav-item .sub-menu li a {
    display: block;
    padding: 8px 20px;
    color: #222;
}
.navbar-nav .nav-item .sub-menu li a.active,
.navbar-nav .nav-item .sub-menu li a:hover {
    padding-left: 25px;
    color: #0898e7;
}

.navbar-nav .sub-nav-toggler {
    display: none;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .navbar-nav .sub-nav-toggler {
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        background: none;
        color: #222;
        font-size: 18px;
        border: 0;
        width: 30px;
        height: 30px;
    }
}
@media (max-width: 767px) {
    .navbar-nav .sub-nav-toggler {
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        background: none;
        color: #222;
        font-size: 18px;
        border: 0;
        width: 30px;
        height: 30px;
    }
}
.navbar-nav .sub-nav-toggler span {
    width: 8px;
    height: 8px;
    border-left: 1px solid #222;
    border-bottom: 1px solid #222;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    position: relative;
    top: -5px;
}

/*===== HEADER HERO =====*/

@media only screen and (min-width: 1400px) {
    .header-hero {
        height: 900px;
    }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .header-hero {
        height: auto;
    }
}
@media (max-width: 767px) {
    .header-hero {
        height: auto;
    }
}
.header-hero .shape {
    position: absolute;
}
.header-hero .shape.shape-1 {
    width: 75px;
    height: 75px;
    background: -webkit-linear-gradient(
        rgba(254, 132, 100, 0.5) 0%,
        rgba(254, 110, 154, 0.5) 100%
    );
    background: -o-linear-gradient(
        rgba(254, 132, 100, 0.5) 0%,
        rgba(254, 110, 154, 0.5) 100%
    );
    background: linear-gradient(
        rgba(254, 132, 100, 0.5) 0%,
        rgba(254, 110, 154, 0.5) 100%
    );
    border-radius: 50%;
    left: 104px;
    top: 24%;
    -webkit-animation: animation1 2s linear infinite;
    -moz-animation: animation1 2s linear infinite;
    -o-animation: animation1 2s linear infinite;
    animation: animation1 2s linear infinite;
}
.header-hero .shape.shape-2 {
    width: 39px;
    height: 39px;
    background: -webkit-linear-gradient(
        rgba(51, 200, 193, 0.5) 0%,
        rgba(17, 155, 210, 0.5) 100%
    );
    background: -o-linear-gradient(
        rgba(51, 200, 193, 0.5) 0%,
        rgba(17, 155, 210, 0.5) 100%
    );
    background: linear-gradient(
        rgba(51, 200, 193, 0.5) 0%,
        rgba(17, 155, 210, 0.5) 100%
    );
    left: 150px;
    bottom: 40px;
    border-radius: 50%;
    -webkit-animation: animation1 2s linear infinite;
    -moz-animation: animation1 2s linear infinite;
    -o-animation: animation1 2s linear infinite;
    animation: animation1 2s linear infinite;
}
.header-hero .shape.shape-3 {
    width: 19px;
    height: 19px;
    background: -webkit-linear-gradient(
        rgba(54, 28, 193, 0.5) 0%,
        rgba(46, 130, 239, 0.5) 100%
    );
    background: -o-linear-gradient(
        rgba(54, 28, 193, 0.5) 0%,
        rgba(46, 130, 239, 0.5) 100%
    );
    background: linear-gradient(
        rgba(54, 28, 193, 0.5) 0%,
        rgba(46, 130, 239, 0.5) 100%
    );
    bottom: 25%;
    left: 26%;
    border-radius: 50%;
    -webkit-animation: animation1 2s linear infinite;
    -moz-animation: animation1 2s linear infinite;
    -o-animation: animation1 2s linear infinite;
    animation: animation1 2s linear infinite;
}
.header-hero .shape.shape-4 {
    background-color: rgba(226, 158, 25, 0.55);
    width: 39px;
    height: 39px;
    border-radius: 50%;
    top: 175px;
    left: 40%;
    -webkit-animation: animation1 2s linear infinite;
    -moz-animation: animation1 2s linear infinite;
    -o-animation: animation1 2s linear infinite;
    animation: animation1 2s linear infinite;
}
.header-hero .shape.shape-5 {
    width: 19px;
    height: 19px;
    background-color: rgba(108, 99, 255, 0.55);
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: 20%;
    border-radius: 50%;
    -webkit-animation: animation1 2s linear infinite;
    -moz-animation: animation1 2s linear infinite;
    -o-animation: animation1 2s linear infinite;
    animation: animation1 2s linear infinite;
}
.header-hero .shape.shape-6 {
    width: 14px;
    height: 14px;
    background-color: rgba(235, 163, 26, 0.55);
    border-radius: 50%;
    left: 45%;
    bottom: 70px;
    -webkit-animation: animation1 2s linear infinite;
    -moz-animation: animation1 2s linear infinite;
    -o-animation: animation1 2s linear infinite;
    animation: animation1 2s linear infinite;
}

.header-shape-1 {
    position: absolute;
    top: 0;
    width: 50%;
    height: 100%;
    right: 0;
    z-index: -1;
    /* background-image: url(../images/header-shape-1.svg); */
    background-position: left center;
    background-repeat: no-repeat;
    background-size: cover;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .header-shape-1 {
        width: 100%;
    }
}

.header-shape-2 {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .header-shape-2 img {
        width: 295px;
    }
}

.header-hero-content {
    position: relative;
    z-index: 9;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .header-hero-content {
        padding-top: 150px;
    }
}
@media (max-width: 767px) {
    .header-hero-content {
        padding-top: 120px;
    }
}
.header-hero-content .header-title {
    font-size: 42px;
    color: var(--color-fg-default);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .header-hero-content .header-title {
        font-size: 36px;
    }
}
@media (max-width: 767px) {
    .header-hero-content .header-title {
        font-size: 22px;
    }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
    .header-hero-content .header-title {
        font-size: 38px;
    }
}
.header-hero-content .header-title span {
    display: contents;
    color: #0898e7;
}
.header-hero-content .text {
    margin-top: 30px;
}
.header-hero-content ul {
    margin-top: 30px;
}
.header-hero-content ul li {
    margin-left: 50px;
}
.header-hero-content ul li:first-child {
    margin-left: 0;
}
.header-hero-content .main-btn {
    margin-top: 10px;
}
.header-hero-content .header-video {
    margin-top: 15px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    color: var(--color-fg-default);
    font-size: 18px;
    border-radius: 50%;
    background-color: #0898e7;
    position: relative;
    z-index: 5;
}
.header-hero-content .header-video::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: rgba(8, 152, 231, 0.8);
    top: 0;
    left: 0;
    -webkit-animation: video 2s linear infinite;
    -moz-animation: video 2s linear infinite;
    -o-animation: video 2s linear infinite;
    animation: video 2s linear infinite;
    z-index: -1;
}

@keyframes video {
    0% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1.4);
        -moz-transform: scale(1.4);
        -ms-transform: scale(1.4);
        -o-transform: scale(1.4);
        transform: scale(1.4);
        opacity: 0;
    }
}

@-webkit-keyframes video {
    0% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1.4);
        -moz-transform: scale(1.4);
        -ms-transform: scale(1.4);
        -o-transform: scale(1.4);
        transform: scale(1.4);
        opacity: 0;
    }
}

.header-image {
    padding-top: 80px;
    position: relative;
    z-index: 5;
}
.header-image .image {
    width: 100%;
}
.header-image .image-shape {
    position: absolute;
    bottom: -100px;
    left: -115px;
    z-index: -1;
}
@media (max-width: 767px) {
    .header-image .image-shape {
        left: -90px;
    }
}
.header-image .image-shape img {
    max-width: 350px;
}
@media (max-width: 767px) {
    .header-image .image-shape img {
        max-width: 250px;
    }
}

.header-hero {
    position: relative;
    z-index: 5;
    background-position: bottom center;
    height: 680px;
}
/*===========================
      03.SERVICES css 
===========================*/

.single-services {
    -webkit-box-shadow: 0px 0px 10px 0px rgba(154, 154, 154, 0.16);
    -moz-box-shadow: 0px 0px 10px 0px rgba(154, 154, 154, 0.16);
    box-shadow: 0px 0px 10px 0px rgba(154, 154, 154, 0.16);
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    padding: 45px 25px 35px;
}
@media (max-width: 767px) {
    .single-services {
        padding: 40px 20px 30px;
    }
}
.single-services .services-icon {
    width: 79px;
    height: 79px;
    border-radius: 50%;
    margin: 0 auto;
    position: relative;
    text-align: center;
}
.single-services .services-icon i {
    font-size: 46px;
    line-height: 79px;
    color: var(--color-fg-default);
}
.single-services .services-icon::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
    border-radius: 50%;
    z-index: -1;
}
.single-services .services-content {
    margin-top: 35px;
}
.single-services .services-content .services-title a {
    font-size: 25px;
    text-decoration: none;
    font-weight: 500;
    color: var(--white-400);
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}
@media (max-width: 767px) {
    .single-services .services-content .services-title a {
        font-size: 20px;
    }
}
.single-services .services-content .text {
    margin-top: 30px;
}
.single-services.services-color-1 .services-icon {
    background-color: #e7a019;
}
.single-services.services-color-1 .services-icon::before {
    background-color: rgba(231, 160, 25, 0.2);
}
.single-services.services-color-1 .services-content .services-title a:hover {
    color: #e7a019;
}
.single-services.services-color-2 .services-icon {
    background-color: #e54f4f;
}
.single-services.services-color-2 .services-icon::before {
    background-color: rgba(229, 79, 79, 0.2);
}
.single-services.services-color-2 .services-content .services-title a:hover {
    color: #e54f4f;
}
.single-services.services-color-3 .services-icon {
    background-color: #8950e4;
}
.single-services.services-color-3 .services-icon::before {
    background-color: rgba(137, 80, 228, 0.2);
}
.single-services.services-color-3 .services-content .services-title a:hover {
    color: #8950e4;
}
.single-services.services-color-4 .services-icon {
    background-color: #0898e7;
}
.single-services.services-color-4 .services-icon::before {
    background-color: rgba(8, 152, 231, 0.2);
}
.single-services.services-color-4 .services-content .services-title a:hover {
    color: #0898e7;
}
.single-services:hover {
    -webkit-box-shadow: 0px 15px 17px 0px rgba(154, 154, 154, 0.16);
    -moz-box-shadow: 0px 15px 17px 0px rgba(154, 154, 154, 0.16);
    box-shadow: 0px 15px 17px 0px rgba(154, 154, 154, 0.16);
}

/*===========================
       04.form css 
===========================*/
.form-area {
    background-color: #f3fdff;
}

.form-image {
    position: relative;
    display: inline-block;
    text-align: center;
    margin-left: 50px;
    padding-top: 100px;
    padding-bottom: 100px;
}
@media (max-width: 767px) {
    .form-image {
        margin-left: 0;
        padding-top: 65px;
        padding-bottom: 45px;
    }
}
.form-image .form-shape {
    background: -webkit-linear-gradient(#61009d 0%, #cb107a 100%);
    background: -o-linear-gradient(#61009d 0%, #cb107a 100%);
    background: linear-gradient(#61009d 0%, #cb107a 100%);
    width: 354px;
    height: 354px;
    border-radius: 50%;
    position: relative;
}
@media (max-width: 767px) {
    .form-image .form-shape {
        width: 294px;
        height: 294px;
    }
}
.form-image .form-shape::before {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    content: '';
    -webkit-transform: scale(1.1) rotate(25deg);
    -moz-transform: scale(1.1) rotate(25deg);
    -ms-transform: scale(1.1) rotate(25deg);
    -o-transform: scale(1.1) rotate(25deg);
    transform: scale(1.1) rotate(25deg);
    border-radius: 50%;
    border: 2px solid #61009d;
    border-top-color: transparent;
    border-right-color: transparent;
}
.form-image .app {
    position: relative;
    -webkit-transform: rotate(-25deg) translateY(-15%);
    -moz-transform: rotate(-25deg) translateY(-15%);
    -ms-transform: rotate(-25deg) translateY(-15%);
    -o-transform: rotate(-25deg) translateY(-15%);
    transform: rotate(-25deg) translateY(-15%);
    max-width: 280px;
    position: absolute;
    top: 10rem;
    left: 95px;
    -webkit-box-shadow: -15px 10px 30px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: -15px 10px 30px 0px rgba(0, 0, 0, 0.3);
    box-shadow: -15px 10px 30px 0px rgba(0, 0, 0, 0.3);
    border-radius: 50px;
}
@media (max-width: 767px) {
    .form-image .app {
        max-width: 190px;
        border-radius: 25px;
        top: 60px;
    }
}

.form-content .main-btn {
    margin-top: 40px;
}

/*===========================
     10.FOOTER css 
===========================*/
.footer-area {
    position: relative;
    z-index: 5;
    background-color: var(--black-400);
}
.footer-area .footer-shape {
    position: absolute;
}
.footer-area .footer-shape.shape-1 {
    background: -webkit-linear-gradient(#fe8464 0%, #fe6e9a 100%);
    background: -o-linear-gradient(#fe8464 0%, #fe6e9a 100%);
    background: linear-gradient(#fe8464 0%, #fe6e9a 100%);
    width: 75px;
    height: 75px;
    border-radius: 50%;
    left: 70px;
    opacity: 0.55;
    -webkit-animation: animation1 2s linear infinite;
    -moz-animation: animation1 2s linear infinite;
    -o-animation: animation1 2s linear infinite;
    animation: animation1 2s linear infinite;
}
@media (max-width: 767px) {
    .footer-area .footer-shape.shape-1 {
        left: auto;
        right: 40px;
        top: 25%;
    }
}
.footer-area .footer-shape.shape-2 {
    background: -webkit-linear-gradient(#33c8c1 0%, #119bd2 100%);
    background: -o-linear-gradient(#33c8c1 0%, #119bd2 100%);
    background: linear-gradient(#33c8c1 0%, #119bd2 100%);
    width: 39px;
    height: 39px;
    border-radius: 50%;
    bottom: 40px;
    left: 15%;
    opacity: 0.55;
    -webkit-animation: animation1 2s linear infinite;
    -moz-animation: animation1 2s linear infinite;
    -o-animation: animation1 2s linear infinite;
    animation: animation1 2s linear infinite;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .footer-area .footer-shape.shape-2 {
        bottom: 85px;
    }
}
@media (max-width: 767px) {
    .footer-area .footer-shape.shape-2 {
        bottom: 110px;
    }
}
.footer-area .footer-shape.shape-3 {
    background: -webkit-linear-gradient(#361cc1 0%, #2e82ef 100%);
    background: -o-linear-gradient(#361cc1 0%, #2e82ef 100%);
    background: linear-gradient(#361cc1 0%, #2e82ef 100%);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    left: 35%;
    top: 40px;
    opacity: 0.55;
    -webkit-animation: animation1 2s linear infinite;
    -moz-animation: animation1 2s linear infinite;
    -o-animation: animation1 2s linear infinite;
    animation: animation1 2s linear infinite;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .footer-area .footer-shape.shape-3 {
        top: 40%;
    }
}
.footer-area .footer-shape.shape-4 {
    background-color: #eba31a;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    left: 50%;
    bottom: 5px;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    opacity: 0.55;
    -webkit-animation: animation1 2s linear infinite;
    -moz-animation: animation1 2s linear infinite;
    -o-animation: animation1 2s linear infinite;
    animation: animation1 2s linear infinite;
}
.footer-area .footer-shape.shape-5 {
    background-color: #e29e19;
    right: 37%;
    top: 0;
    width: 39px;
    height: 39px;
    border-radius: 50%;
    opacity: 0.55;
    -webkit-animation: animation1 2s linear infinite;
    -moz-animation: animation1 2s linear infinite;
    -o-animation: animation1 2s linear infinite;
    animation: animation1 2s linear infinite;
}
.footer-area .footer-shape.shape-6 {
    background-color: #b60d81;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    top: 125px;
    right: 15%;
    opacity: 0.55;
    -webkit-animation: animation1 2s linear infinite;
    -moz-animation: animation1 2s linear infinite;
    -o-animation: animation1 2s linear infinite;
    animation: animation1 2s linear infinite;
}
.footer-area .footer-shape.shape-7 {
    background-color: #6c63ff;
    width: 64px;
    height: 64px;
    bottom: 25px;
    right: 8%;
    opacity: 0.55;
    border-radius: 50%;
    -webkit-animation: animation1 2s linear infinite;
    -moz-animation: animation1 2s linear infinite;
    -o-animation: animation1 2s linear infinite;
    animation: animation1 2s linear infinite;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .footer-area .footer-shape.shape-7 {
        bottom: 20%;
    }
}
@media (max-width: 767px) {
    .footer-area .footer-shape.shape-7 {
        bottom: 38%;
    }
}
.footer-area .footer-shape.shape-8 {
    top: 30px;
    right: -45px;
}
.footer-area .logo {
    max-width: 200px;
}

.footer-about {
    max-width: 320px;
}
.footer-about .text {
    margin-top: 30px;
}
.footer-about .social {
    margin-top: 30px;
}
.footer-about .social li {
    display: inline-block;
    margin-right: 25px;
}
.footer-about .social li a {
    font-size: 20px;
    color: #747e88;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}
.footer-about .social li a:hover {
    color: #0898e7;
}

.footer-title .title {
    font-size: 20px;
    font-weight: 500;
}

.footer-link .link {
    padding-top: 10px;
}
.footer-link .link li {
    margin-top: 10px;
}
.footer-link .link li a {
    color: #747e88;
    font-size: 18px;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}
.footer-link .link li a:hover {
    color: #0898e7;
}

.footer-link-wrapper {
    width: 50%;
}

.footer-contact .contact-list li {
    margin-top: 20px;
}
.footer-contact .contact-list li .contact-info .info-info {
    width: 30px;
}
.footer-contact .contact-list li .contact-info .info-content .text {
    color: #747e88;
    font-size: 16px;
}
.footer-contact .contact-list li .contact-info .info-content .text a {
    color: #747e88;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}
.footer-contact .contact-list li .contact-info .info-content .text a:hover {
    color: #0898e7;
}

.footer-copyright .copyright {
    border-top: 2px solid #747e88;
    padding-top: 10px;
    padding-bottom: 25px;
}
.footer-copyright .copyright .copyright-text {
    padding-top: 15px;
}
.footer-copyright .copyright .copyright-text .text {
    color: #747e88;
}
.footer-copyright .copyright .copyright-privacy {
    padding-top: 15px;
}
.footer-copyright .copyright .copyright-privacy a {
    font-size: 16px;
    color: #747e88;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}
.footer-copyright .copyright .copyright-privacy a:hover {
    color: #0898e7;
}

/*===========================
       MARKDOWN
===========================*/

/* #image-background {
    display: table;
    width: 100%;
    height: 100vh;
    z-index: -500;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
} */

.loader:empty {
    position: absolute;
    top: calc(50% - 4em);
    left: calc(50% - 4em);
    width: 8em !important;
    height: 8em !important;
    border: 1.1em solid rgba(255, 255, 255, 0.2);
    border-left: 1.1em solid #fff;
    border-radius: 50%;
    animation: load8 1.1s infinite linear;
}

@keyframes load8 {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.card-dark {
    background-color: rgba(0, 0, 0, 60%);
}

#title {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    color: var(--color-fg-default);
    text-align: center;
    font-family: 'lato', sans-serif;
    font-weight: 300;
    font-size: 50px;
    letter-spacing: 10px;
    margin-top: -60px;
    padding-left: 10px;
}
#title span {
    background: -webkit-linear-gradient(white, #38495a);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@keyframes animStar {
    from {
        transform: translateY(0px);
    }
    to {
        transform: translateY(-2000px);
    }
}

#loading {
    width: 300px;
    -webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;
    animation: spin 10s linear infinite;
    left: 0;
    right: 0;
    top: 50%;
    z-index: 1;
    text-align: center;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}

.markdown-body h1,
.markdown-body h2,
.markdown-body h3,
.markdown-body h4,
.markdown-body h5,
.markdown-body h6 {
    position: relative;
    margin-top: 1.1em;
    margin-bottom: 16px;
    font-weight: 700;
    line-height: 2;
    color: var(--main-color);
}

.markdown-body h1 {
    margin-top: 0.5em;
}

.markdown-body h2 {
    font-size: 1.75em;
    margin: 1.5em 0 0.6em;
}

.markdown-body ol,
.markdown-body ul {
    padding-left: 2em;
}

.markdown-body ul {
    padding-left: 2em;
    list-style-type: circle;
}

.markdown-body li {
    line-height: 2;
}

.markdown-body li:not(:last-child) {
    margin-bottom: 10px;
}

.markdown-body blockquote,
.markdown-body dl,
.markdown-body ol,
.markdown-body p,
.markdown-body pre,
.markdown-body table,
.markdown-body ul {
    margin-top: 0;
    margin-bottom: 16px;
    line-height: 2;
    color: '#bcb9b7';
}
.markdown-body p {
    font-weight: 400;
    letter-spacing: -0.003em;
    font-size: 1em;
    word-break: break-word;
    word-wrap: break-word;
    line-height: 2;
}

.markdown-body blockquote {
    overflow: auto;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    background-color: #121212;
    border-left: 3px solid #5488c7;
}

.markdown-body blockquote::before {
    content: '\201C';
}

.markdown-body blockquote::after {
    content: '\201D';
}

.title-divider {
    border-bottom: 1px solid #ececec;
    padding-bottom: 10px;
}

.markdown-body table {
    border-collapse: collapse;
    border-spacing: 0;
    width: auto;
    overflow: auto;
    word-break: normal;
    word-break: keep-all;
    margin-bottom: 16px;
    margin: 1.6em auto;
}

.markdown-body table tr {
    background-color: #121212;
    border-top: 1px solid #ccc;
}

.markdown-body table td,
.markdown-body table th {
    padding: 8px 15px;
    border: 1px solid #ddd;
}

.markdown-body table th {
    background-color: #3d3d3d;
    font-size: 19px;
}
.markdown-body table tr:nth-child(2n) {
    background-color: #3d3d3d;
}

.limit-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.post-content {
    background-color: var(--black-400);
    padding: 0 !important;
}

.effect-title {
    font-size: 1.25rem;
    padding: 5px 10px;
    border-radius: 3px;
    border: 1px solid var(--color-fg-default);
    color: var(--color-fg-default) !important;
    margin-bottom: 12px;
}

.bg-cloud {
    background: url(https://www.astrology.com/images-US/backgrounds/bg-clouds-header-1440w.jpg)
        no-repeat center top/100%;
    min-height: 100vh;
}
