.pdf-container {
    flex: 1;
    background: #eee;
    height: 70vh;
    max-height: 900px;
    overflow-y: scroll;
}

.pdf-toolbar {
    text-align: center;
    background-color: #555;
    padding: 0.5em;
}

.pdf-viewport {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.pdf-viewport canvas {
    width: 100%;
    box-shadow: 0 2px 5px grey;
    margin: 5px 0;
}
