.nav-ribbon-wrapper {
    padding: 0 15px;
    position: relative;
    margin: 1rem auto 0;
    max-width: 1140px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.nav-ribbon-wrapper > nav {
    justify-content: center;
    overflow-y: hidden;
    display: flex;
    align-items: center;
}

.nav-ribbon-wrapper .nav__items:first-child {
    display: inline-grid;
    grid-column-gap: 15px;
    grid-template-columns: repeat(6, auto);
}

.shopping-header {
    text-align: center;
    margin: 0 0 5em;
}

.shopping-header__description {
    margin: 0 auto;
    max-width: 870px;
    padding: 0;
    width: 100%;
}

.shopping-header__description > p {
    text-align: center;
}

.shopping-searching {
    margin-bottom: 2.617924em;
    display: block;
}
