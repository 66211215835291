#video-galaxy {
    position: fixed;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    object-fit: cover;
}

.visible-video {
    visibility: visible;
}

.hidden-video {
    visibility: hidden;
    opacity: 0;
    transition: visibility 1s 3s, opacity 3s linear;
}

.visible-content {
    opacity: 0;
    animation: fadeIn 1s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
}

.hidden-content {
    display: none;
}

#puse-number {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 160px;
    line-height: 160px;
    height: 160px;
    font-weight: 600;
    z-index: 1;
    color: var(--white-base);
}

.text-shadow-highlight {
    text-shadow: 0 0 10px var(--blue-700), 0 0 20px var(--blue-700),
        0 0 30px var(--blue-700), 0 0 1px var(--blue-500),
        0 0 9px var(--blue-500), 0 0 0px var(--blue-500),
        0 0 100px var(--blue-500), 0 0 150px var(--blue-500);
}

.text-shadow-highlight2 {
    text-shadow: 0 0 10px var(--white-base), 0 0 20px var(--white-base),
        0 0 30px var(--white-base), 0 0 40px var(--blue-500),
        0 0 70px var(--blue-500), 0 0 80px var(--blue-500),
        0 0 100px var(--blue-500), 0 0 150px var(--blue-500);
}

.jump {
    transform-origin: 50% 50%;
    animation: jump 0.65s linear alternate infinite;
}

.blink {
    animation: blink 1.5s linear infinite;
}

.pulse {
    animation: pulse 1s infinite;
    font-size: 18px !important;
    color: var(--red-base) !important;
}

.card-hovered {
    transform: scale3d(1.02, 1.02, 1);
}

table.collapse {
    border-collapse: collapse;
    margin: auto;
}

table.collapse td {
    text-align: center;
}

td.bd {
    width: 4rem;
    height: 4rem;
    color: var(--red-base);
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 2px;
}

td.bd-none {
    /* width: 8rem; */
    height: 4rem;
    font-size: 18px;
}

td.bd-sm {
    width: 1em;
    height: 2em;
    color: #90caf9;
    font-size: 2.1rem;
    font-family: sans-serif;
    font-weight: 400;
}

td.red-base {
    color: var(--red-base);
}

td.gray-base {
    color: var(--dark-grey-500);
}

td.bottom {
    vertical-align: bottom;
    color: var(--red-base);
    font-weight: 600;
}

td.top {
    vertical-align: top;
    color: var(--red-base);
    font-weight: 600;
}

td.bd-sm.text-sm {
    font-size: 15px;
}

td.border-b {
    border-bottom: 2px solid #90caf9;
}

td.border-r {
    border-right: 2px solid #90caf9;
}

.base-1 {
    top: 20em;
}

#chart-canvas {
    max-width: 35rem;
    min-width: 20rem;
    margin: auto;
}

.so-dinh-cao {
    position: absolute;
    font-weight: bold;
    font-size: 16px;
    line-height: 1.5;
    color: var(--blue-500);
}

#no-1 {
    left: 38px;
    bottom: -8px;
}
#no-2 {
    left: 146px;
    bottom: -8px;
}
#no-3 {
    right: 46px;
    bottom: -8px;
}
#no-4 {
    left: 96px;
    bottom: 38px;
}
#no-5 {
    right: 96px;
    bottom: 38px;
}
#no-6 {
    left: 146px;
    top: 55px;
}
#no-7 {
    left: 146px;
    top: 13px;
}

@keyframes jump {
    0% {
        transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
    }
    40% {
        transform: translate3d(0, 30%, 0) scale3d(0.7, 1.5, 1);
    }
    100% {
        transform: translate3d(0, 100%, 0) scale3d(1.5, 0.7, 1);
    }
}

@keyframes blinkTextCursor {
    from {
        border-right-color: rgba(255, 255, 255, 0.75);
    }
    to {
        border-right-color: transparent;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
    }

    70% {
        transform: scale(1.05);
        box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
    }
}

@keyframes blink {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}
