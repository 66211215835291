.post-table-contents {
    position: -webkit-sticky;
    position: sticky;
    top: 4rem;
    align-self: flex-start;
    max-height: calc(100vh - 40px);
    overflow: auto;
}

.list-item-selected {
    border-left: 3px solid;
    color: var(--main-color);
}
