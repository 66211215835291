.category {
    margin-bottom: 30px;
}

.category__item {
    padding: 16px 0 20px;
}

.order {
    display: flex;
    flex-direction: column;
    gap: 0;
    margin: 12px 0;
    box-shadow: 0 1px 1px 0 rgb(0 0 0 / 5%);
    border-radius: 0.125rem;
    /* border: 1px solid var(--main-color); */
}

.order__summary {
    /* padding: 12px 24px; */
    margin-top: 15px;
}

.order__summary__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.order__summary__info {
    margin-top: 16px;
}

.order__summary__info__package {
    padding: 12px 0 0;
    margin-bottom: 20px;
    align-items: center;
    flex-wrap: nowrap;
    display: flex;
    word-wrap: break-word;
}

.order__sumary__info__package--left {
    padding: 0 12px 0 0;
    flex: 1;
    align-items: flex-start;
    flex-wrap: nowrap;
    display: flex;
    word-wrap: break-word;
}
.order__price {
    padding: 24px 24px 12px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.order__total-price {
    font-size: 24px;
    line-height: 30px;
}
.package-name {
    min-width: 0;
    padding: 0 0 0 12px;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    word-wrap: break-word;
}
.package-thumnail {
    width: 5rem;
    height: 5rem;
    background-image: url('https://cf.shopee.vn/file/f7f3596e3e41f7a15e5f86bfcbc7635a_tn');
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    top: 0;
    left: 0;
}

.order__actions {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
    padding: 12px 24px 24px;
    align-content: center;
}
.btn-big {
    min-width: 150px;
    min-height: 40px;
}
