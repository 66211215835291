html,
body,
#root {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    overflow: auto;
}

.text-box {
    background: inherit;
    background-color: rgba(0, 0, 0, 0.3);
    /* border: 15px solid white; */
    border-radius: 5%;
    padding: 50px;
    margin: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
}

.parent {
    position: relative;
    display: flex;
    /* overflow: auto; */
    justify-content: center;
    align-items: center;
}

/* 
.slogan {
  font: bold 50px/55px Novecento !important;
  padding: 30px 0;
  flex-wrap: wrap;
  display: inline-flex;
  text-transform: uppercase;
  justify-content: center !important;
} */
.text-info {
    background: rgb(57, 78, 96, 0.4);
    display: flex;
    align-items: center;
    border-radius: 3%;
    padding: 10px;
    margin: 10px;
}

.custom-info {
    display: flex;
    align-items: center;
    flex-direction: column;
}

@media only screen and (max-width: 768px) {
    .slogan {
        font: bold 15px/20px Novecento !important;
        padding: 30px 0;
        flex-wrap: wrap;
        display: inline-flex;
        text-transform: uppercase;
        justify-content: center !important;
    }
}

@media only screen and (min-width: 769px) {
    .slogan {
        font: bold 30px/35px Novecento !important;
        padding: 30px 0;
        flex-wrap: wrap;
        display: inline-flex;
        text-transform: uppercase;
        justify-content: center !important;
    }
}

@media only screen and (max-width: 768px) {
    .slogan {
        font: bold 15px/20px Novecento !important;
        padding: 30px 0;
        flex-wrap: wrap;
        display: inline-flex;
        text-transform: uppercase;
        justify-content: center !important;
    }
}

@media only screen and (min-width: 769px) {
    .slogan {
        font: bold 30px/35px Novecento !important;
        padding: 30px 0;
        flex-wrap: wrap;
        display: inline-flex;
        text-transform: uppercase;
        justify-content: center !important;
    }
}
