.post-actions {
    position: -webkit-sticky;
    position: sticky;
    top: 4rem;
    align-self: flex-start;
    padding-bottom: 100px;
}

@media (max-width: 1200px) {
    .hidden-md-down {
        display: none !important;
    }
}

@media (min-width: 1200px) {
    .hidden-lg-up {
        display: none !important;
    }
}
